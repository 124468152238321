$env: production;
@import '@/styles/_component-base.styles.scss';

.section {
    padding: spacer(3xl);

    &Title {
        margin-bottom: spacer(xl);

        @include only-breakpoint(xs) {
            max-width: 295px;
            margin-left: auto;
            margin-right: auto;
        }

        @include breakpoint(md) {
            margin-bottom: spacer(md);
        }

        @include breakpoint(lg) {
            margin-bottom: spacer(xl);

        }
    }
}

// TODO: move to product tile bit component
.title,
.subtitle {
    text-align: left;
}

.defaultSlider {
    display: none;

    @include breakpoint(md) {
        display: block;
    }
}

.twoItemsSlider {
    max-width: 970px;
    margin: 0 auto;
}

.oneItemSlider {
    max-width: 460px;
    margin: 0 auto;
}

.mobileSlider {
    display: block;

    @include breakpoint(md) {
        display: none;
    }
}

.tile {
    padding: 0 spacer(2xs);
    max-width: 100%;

    // TODO: Whenever we use badges in the tiles we need to override the display inline
    // that anchors have by default, update product detail tile on bit so we dont have to
    a {
        display: block;
    }

    @include breakpoint(md) {
        padding: 0 spacer(sm);
        max-width: none;
    }
}

.paginationDots {
    margin-top: spacer(2xl);
}

.buttons {
    z-index: 1;

    @include breakpoint(sm, max) {
        padding: spacer(xs) spacer(sm) spacer(2xs);
        border-radius: 40px;
        background-color: rgba(255, 255, 255, 0.55);
        top: 37%
    }

    @include breakpoint(md) {
        top: 29%
    }

    @include breakpoint(lg) {
        top: 39%
    }
}

.rightButton {
    @include breakpoint (sm, max) {
        right: calc(spacer(3xl) * -1);
    }
}

.leftButton {
    @include breakpoint (md, max) {
        left: calc(spacer(3xl) * -1);
    }
}

.hideSliderControls {
    @include breakpoint (md) {
        display: none;
    }
}

.manualHideSliderButtons{
    display: none;
}

.loaderContainer{
    position: relative;
}